import React from 'react'
import {Link} from "gatsby"
import Layout from '../components/layout'
import SEO from "../components/seo"

const ContactPage = () => (
    <Layout>
        <SEO title='Contact' description='Chai Chuah' />
        <div class="container py-6">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <h1 class="display-4 mb-4">Contact Me</h1>
                    <hr class="border-1 border-light my-4 ml-0 text-left w-15" role="presentation" />
                    <p class="lead text-secondary">Ask me anything, or share your feedback.</p>
                </div>
            </div>
        </div>

        <div class="bg-white text-black">
            <div class="container py-2">
                <div class="bg-white mt-n6 px-3 px-lg-0">
                    <div class="row justify-content-center py-4 py-lg-5">
                        <div class="col-md-10 col-lg-8">
                            <form name="contact" method="POST" class="py-4" data-netlify="true" netlify-honeypot="bot-field">
                                <input type="hidden" name="form-name" value="contact" />
                                <div class="form-group">
                                    <label htmlFor="inputName">Name </label>
                                    <input type="text" class="form-control" id="inputName" name="name" placeholder="Enter your name" required />
                                </div>
                                <div class="form-group">
                                    <label htmlFor="inputEmail">Email </label>
                                    <input type="email" class="form-control" id="inputEmail" name="email" placeholder="Enter your email address" required />
                                </div>
                                <div class="form-group">
                                    <label htmlFor="messageTetxarea">Message </label>
                                    <textarea class="form-control" id="messageTetxarea" name="message" rows="9" placeholder="Enter your message" required></textarea>
                                </div>
                                <div class="form-group form-check">
                                    <input type="checkbox" class="form-check-input" id="inputCheck" />
                                    <label class="form-check-label small" htmlFor="inputCheck">I agree with the <Link to="/">terms and conditions</Link> </label>
                                </div>
                                <button type="submit" class="btn btn-pill btn-outline-dark">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default ContactPage